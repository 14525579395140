<template>
    <span>
        <v-form
            v-if="calc"
            ref="form"
            lazy-validation
            @submit.prevent="calculateClicked()"
        >
            <v-card-text>
                <div v-if="!calc.inputs" class="description">
                    This calculation has no inputs ~(˘▾˘~)
                </div>
                <CalcInputContainer :calc="calc">
                    <v-flex
                        v-for="(input, index) in calc.inputs"
                        :key="index"
                        xs12
                        sm6
                        md3
                        pr-5
                        v-if="!isInputGrouped(input)"
                    >
                        <CalcInput
                            v-model="input.value"
                            :input-details="input"
                        />
                    </v-flex>
                </CalcInputContainer>
                <div v-if="calc.inputGroups && calc.inputGroups.length > 0">
                    <CalcInputGroup
                        :input-group="inputGroup"
                        v-for="(inputGroup, index) in calc.inputGroups"
                        :key="index"
                    />
                </div>
            </v-card-text>
            <v-card-actions class="d-flex align-baseline pl-4">
                <div class="mr-5">
                    <v-btn
                        color="accent"
                        :disabled="!inputsFilled"
                        class="mr-5"
                        type="submit"
                        >Calculate</v-btn
                    >
                    <div style="min-height: 20px">
                        <v-expand-transition>
                            <div
                                class="red--text pb-0 mb-0"
                                style="font-size: 12px"
                                v-show="!inputsFilled"
                            >
                                Required inputs missing
                            </div>
                        </v-expand-transition>
                    </div>
                </div>
                <v-select
                    v-if="examples.length > 0"
                    @change="exampleClick"
                    v-model="selectedExample"
                    style="max-width: 15rem"
                    :items="examples"
                    item-text="title"
                    :item-value="(item) => item"
                    label="Trial inputs"
                    outlined
                    dense
                >
                    <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    style="
                                        -webkit-line-clamp: unset !important;
                                        overflow-x: hidden;
                                        text-overflow: ellipsis;
                                        white-space: unset;
                                    "
                                >
                                    {{ item.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </v-card-actions>
        </v-form>
        <Results
            v-if="calcRecords"
            :key="calc.calcId"
            :calc-records="calcRecords"
            :show-calc-info="false"
            :calc="calc"
        />
    </span>
</template>

<script>
import Vue from 'vue'
import VueKatex from 'vue-katex'
import Results from './Results.vue'
import CalcInput from './CalcInput.vue'
import CalcInputGroup from './CalcInputGroup.vue'
import CalcTextContainer from './CalcTextContainer.vue'
import CalcInputContainer from './CalcInputContainer.vue'
import { mapActions } from 'vuex'
import helpers from '../../../helpers'
import 'katex/dist/katex.min.css'
Vue.use(VueKatex)
export default {
    components: {
        Results,
        CalcInput,
        CalcInputGroup,
        CalcInputContainer,
        CalcTextContainer,
    },
    props: {
        /**
         * selected calc
         */
        calc: {
            type: Object,
        },
    },
    data() {
        return {
            jsonlink: 'https://compute.arup.digital/docs/explanation/JSON.html',
            examples: [],
            selectedExample: {},
            includesNonGroupedInputs: false,
            calcRecords: [],
        }
    },
    watch: {
        'calc.inputGroups': {
            handler(val) {
                val.forEach((v) => {
                    if (v.inputs) {
                        v.inputs.forEach((i) => {
                            const input = this.calc.inputs.find(
                                (ci) => ci.inputId === i.inputId
                            )
                            input.value = i.value
                        })
                    }
                })
            },
            deep: true,
        },
    },
    computed: {
        inputsFilled() {
            if (this.calc.inputs) {
                const filtered = this.calc.inputs.filter((i) => i.value)
                return filtered.length === this.calc.inputs.length
            }
            return true
        },
    },
    async mounted() {
        if (
            !this.calc.inputGroups ||
            (this.calc.inputGroups && this.calc.inputGroups.length === 0)
        )
            this.includesNonGroupedInputs = true
        else if (this.calc.inputGroups) {
            let inputsGrouped = 0
            console.log('this.calc.inputGroups:', this.calc.inputGroups)
            this.calc.inputGroups.forEach(
                (ig) => (inputsGrouped += ig.inputs ? ig.inputs.length : 0)
            )
            if (inputsGrouped < this.calc.inputs.length)
                this.includesNonGroupedInputs = true
        }
        this.example()
    },
    methods: {
        ...mapActions(['calculate', 'getExamples']),
        isInputGrouped(input) {
            for (let group in this.calc.inputGroups) {
                for (let i in this.calc.inputGroups[group].inputs) {
                    if (
                        this.calc.inputGroups[group].inputs[i].inputId ===
                        input.inputId
                    )
                        return true
                }
            }
            return false
        },
        exampleClick() {
            this.calc.inputs.forEach((i) => (i.value = ''))
            this.selectedExample.inputs.forEach(({ name, value }) => {
                const input = this.calc.inputs.find((i) => i.name === name)
                if (input) {
                    // Convert true/false to True/False
                    if (input.type === 'System.Boolean') {
                        input.value =
                            value[0].toUpperCase() +
                            value.slice(1).toLowerCase()
                    } else {
                        input.value = value
                    }
                }
            })

            if (this.calc.inputGroups) {
                this.calc.inputGroups.forEach((ig) => {
                    ig.inputs.forEach((i) => {
                        const input = this.selectedExample.inputs.find(
                            (se) => se.name === i.name
                        )
                        if (input) {
                            i.value = input.value
                        }
                    })
                })
            }

            // track feature usage
            if (this.$posthog && this.$posthog.__loaded) {
                this.$posthog.capture('trialInputsClick', {
                    calcName: this.calc.name,
                    calcFullname: this.calc.fullname,
                    persistentCalcId: this.calc.persistentCalcID,
                    exampleTitle: this.selectedExample.title,
                })
            }
        },
        async example() {
            if (this.calc.calcId) {
                const examples = await this.getExamples({
                    calcId: this.calc.calcId,
                })
                this.examples = examples
            }
        },
        /**
         * Set url params for calc input values
         */
        setUrlQueryParams() {
            var queryobj = {}
            if (this.calc.inputs) {
                this.calc.inputs.forEach(function (i) {
                    queryobj[i.name] = i.value
                })
            }
            if (
                JSON.stringify(queryobj) !== JSON.stringify(this.$route.query)
            ) {
                this.$router.replace({
                    name: this.$route.name,
                    query: queryobj,
                })
            }
        },
        /**
         * Generate body for each input to parse to API
         */
        genBody() {
            let body = {}
            if (this.calc.inputs) {
                this.calc.inputs.forEach((input) => {
                    body[input.name] = helpers.valueToSend(input)
                })
            }
            return body
        },
        /**
         * Calculate the result of the calc with the inputs
         */
        async calculateClicked() {
            if (!this.$refs.form.validate()) return
            let body = this.genBody()
            let calc = await this.calculate({
                body: body,
                calcId: this.calc.calcId,
            })
            if (calc) {
                let records = this.calcRecords

                if (Array.isArray(records) && records.length) {
                    records.unshift(calc)
                } else {
                    records = [calc]
                }

                this.calcRecords = records
            }
            this.setUrlQueryParams()
        },
    },
}
</script>
<style lang="scss">
span {
    .mord.accent {
        background-color: inherit !important;
        border-color: inherit !important;
    }
}

.v-label {
    height: auto !important; // needed to prevent typographic extensions below the baseline from being cut off
}
</style>
