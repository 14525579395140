<template>
    <v-lazy v-model="isActive">
        <span :class="flexClass">
            <span class="d-flex flex-nowrap align-center">
                <CalcStatChip
                    :class="_noLeftMargin ? 'ml-0' : ''"
                    :value="totalProjects.val"
                    :loaded="totalProjects.loaded"
                    color="#ffddee"
                    icon="mdi-domain"
                    tooltip="Total projects"
                />
                <CalcStatChip
                    :value="this.uniqueUsers"
                    :loaded="this.loaded"
                    color="#FFF780"
                    icon="mdi-account-outline"
                    tooltip="Unique users"
                />
            </span>
            <span class="d-flex flex-nowrap align-center" style="width: '100%'">
                <CalcStatChip
                    :value="this.totalUsage"
                    :loaded="this.loaded"
                    color="#CCF7CP"
                    icon="mdi-chart-line-variant"
                    tooltip="Total usage"
                />
                <ClickableCalcStatChip
                    v-if="!disableClickable"
                    :value="likes.total"
                    :loaded="likes.loaded"
                    :on="likes.likedByUser"
                    :tooltipMessage="tooltipMessage"
                    :noRightMargin="noRightMargin"
                    color="#e5f491"
                    onIcon="mdi-thumb-up"
                    offIcon="mdi-thumb-up-outline"
                    tooltip="Likes"
                    @toggle="toggle"
                />
                <CalcStatChip
                    v-else
                    :value="likes.total"
                    :loaded="likes.loaded"
                    color="#e5f491"
                    icon="mdi-thumb-up-outline"
                    tooltip="Likes"
                />
            </span>
        </span>
    </v-lazy>
</template>

<script>
import { mapActions } from 'vuex'
import CalcStatChip from './CalcStatChip.vue'
import ClickableCalcStatChip from './ClickableCalcStatChip.vue'
export default {
    name: 'CalcStatsChips',
    components: { CalcStatChip, ClickableCalcStatChip },
    props: {
        calcId: {
            type: Number,
        },
        persistentCalcId: {
            type: String,
        },
        noLeftMargin: {
            type: Boolean,
        },
        manuallySetVals: {
            // == true then the parent should pass in values
            type: Boolean,
        },
        manualLoading: {
            type: Boolean,
        },
        manualLikes: {
            type: String,
        },
        manualTotalProjects: {
            type: String,
        },
        manualUniqueUsers: {
            type: String,
        },
        manualTotalUsage: {
            type: String,
        },
        disableClickable: {
            type: Boolean,
        },
        moveToEnd: {
            type: Boolean,
        },
        noRightMargin: {
            type: Boolean,
        },
        noWrap: {
            type: Boolean,
        },
    },
    data() {
        return {
            stats: [],
            loaded: false,
            likes: {
                loaded: false,
                total: 0,
                likedByUser: false,
            },
            totalProjects: {
                loaded: false,
                val: 0,
            },
            isActive: false,
        }
    },
    async mounted() {
        if (this.manuallySetVals) {
            this.likes.loaded = !this.manualLoading
            this.likes.total = this.manualLikes
            this.likes.likedByUser = false

            this.totalProjects.val = this.manualTotalProjects
            this.totalProjects.loaded = !this.manualLoading
        }
    },
    watch: {
        isActive: async function (newVal, oldVal) {
            if (newVal && !oldVal && !this.manuallySetVals) {
                this.getCalcLikes({
                    calcId: this.calcId,
                    persistentCalcId: this.persistentCalcId,
                }).then((res) => {
                    // res interface: { total: number; likedByUser: boolean }
                    this.likes.loaded = true
                    this.likes.total = res.total
                    this.likes.likedByUser = res.likedByUser
                })

                this.getTotalCalcProjects({ calcId: this.calcId }).then(
                    (res) => {
                        this.totalProjects.val = res
                        this.totalProjects.loaded = true
                    }
                )

                let stats = await this.getCalcStats(this.calcId)

                if (stats) {
                    this.stats = stats
                }

                this.loaded = true
            }
        },
        manualLoading: function (newVal) {
            if (newVal === false) {
                this.likes.loaded = !this.manualLoading
                this.likes.total = this.manualLikes
                this.likes.likedByUser = false

                this.totalProjects.val = this.manualTotalProjects
                this.totalProjects.loaded = !this.manualLoading

                this.loaded = true
            }
        },
    },
    computed: {
        flexClass() {
            let cls = 'd-flex'
            if (!this.noWrap) cls += ' flex-wrap'
            if (this.moveToEnd) cls += ' justify-end'

            return cls
        },
        _noLeftMargin() {
            // explicitly returning true/false as value may be undefined
            return this.noLeftMargin ? true : false
        },
        totalUsage() {
            if (this.manualTotalUsage) return this.manualTotalUsage

            if (!this.stats) {
                return 0
            }

            if (!this.stats.total) {
                return 0
            }

            return this.compact(this.stats.total)
        },
        tooltipMessage() {
            return this.likes.likedByUser ? 'Liked' : 'Like Calculation'
        },
        uniqueUsers() {
            if (this.manualUniqueUsers) return this.manualUniqueUsers
            if (!this.stats) {
                return 0
            }

            if (!this.stats.users) {
                return 0
            }

            if (this.stats.length == 0) {
                return 0
            }

            return this.compact(this.stats.users.length)
        },
    },
    methods: {
        ...mapActions([
            'getCalcStats',
            'getCalcLikes',
            'addCalcLike',
            'deleteCalcLike',
            'getTotalCalcProjects',
        ]),
        compact(number) {
            let formatter = Intl.NumberFormat('en', {
                notation: 'compact',
                compactDisplay: 'short',
            })
            return formatter.format(number)
        },
        async toggle() {
            this.$emit('toggle')
            this.likes.loaded = false
            let success = false
            if (this.likes.likedByUser) {
                success = await this.deleteCalcLike({
                    calcId: this.calcId,
                    persistentCalcId: this.persistentCalcId,
                })
            } else {
                success = await this.addCalcLike({
                    calcId: this.calcId,
                    persistentCalcId: this.persistentCalcId,
                })
            }
            if (success) {
                this.likes.total += !this.likes.likedByUser ? 1 : -1
                this.likes.likedByUser = !this.likes.likedByUser
            }
            this.likes.loaded = true
        },
    },
}
</script>
