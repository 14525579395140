<template>
    <transition name="component-fade" mode="out-in">
        <div id="resultBox">
            <br />
            <v-progress-linear
                indeterminate
                v-if="showProgressResults"
                color="accent"
            ></v-progress-linear>

            <div v-if="calcRecords.length > 0">
                <v-list three-line>
                    <v-subheader v-if="!showCalcInfo">Results</v-subheader>
                    <!-- multiple results -->
                    <v-list-group
                        v-for="calcRecord in calcRecords"
                        :key="calcRecord.calcRecordId"
                    >
                        <div
                            style="width: 100%"
                            slot="activator"
                            @mouseover="calcRecord.mouseOver = true"
                            @mouseleave="calcRecord.mouseOver = false"
                        >
                            <v-progress-linear
                                v-if="calcRecord.showProgress"
                                :indeterminate="true"
                                class="ma-0"
                            ></v-progress-linear>
                            <Result
                                :calcRecord="calcRecord"
                                :showCalcInfo="showCalcInfo"
                            />
                        </div>

                        <!-- SUB TILE - SUB RESULTS -->
                        <div
                            v-if="
                                !calcRecord.isSingleResults && calcRecord.output
                            "
                        >
                            <v-list-item
                                v-for="(r, i) in calcRecord.output
                                    .arupComputeResultItems"
                                :key="i"
                            >
                                <v-list-item-action></v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span
                                            v-katex:auto="{
                                                options: katexOptions,
                                            }"
                                            v-if="r.html"
                                            v-html="r.html"
                                        />
                                        <span v-else>
                                            <span
                                                v-katex:auto="{
                                                    options: katexOptions,
                                                }"
                                                v-html="getKaTeX(r).html"
                                            />
                                        </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <p class="grey--text">
                                            {{ getKaTeX(r).description }}
                                        </p>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <div v-else>
                            <v-list-item>
                                <v-list-item-action></v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span
                                            v-if="
                                                calcRecord && calcRecord.output
                                            "
                                            >Result:
                                            {{ calcRecord.output.result }}</span
                                        >
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list-group>
                </v-list>
            </div>
        </div>
    </transition>
</template>
<script>
import helpers from './../../../helpers'
import Result from './Result.vue'
import { mapState } from 'vuex'
import 'katex/dist/katex.min.css'
export default {
    components: { Result },
    props: {
        /**
         * calc records - results of the calc
         */
        calcRecords: {
            type: Array,
            required: true,
        },
        /**
         * Show info of calc if not single result
         */
        showCalcInfo: {
            type: Boolean,
            required: true,
        },
        calc: {
            type: Object,
        },
    },
    data() {
        return {
            /**
             * Whether page is loading
             */
            loading: true,
            /**
             * Options how katex math is displayed
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
        }
    },
    computed: {
        ...mapState(['showProgressResults']),
    },
    methods: {
        /**
         * Get greek name
         * @param {String} name greek name
         */
        getGreekName(name) {
            helpers.getGreekName(name)
        },
        getKaTeX(ri) {
            let r = structuredClone(ri)

            if (this.calc.outputs) {
                for (const output of this.calc.outputs) {
                    if (output.name == r.symbol) {
                        r.symbol = output.name
                        r.latex = output.latex
                        r.description = output.description
                        r.units = output.units
                    }
                }
            }

            if (!r.units) {
                r.units = ''
            }

            if (!r.symbol) {
                r.symbol = '?'
            }

            if (!r.latex) {
                if (r.laTeX) {
                    r.latex = r.laTeX
                } else {
                    r.latex = r.symbol
                }
            }

            let html = `$$${r.latex} = $$ ${r.value} $$\\, ${r.units}$$`

            return { html: html, description: r.description }
        },
    },
}
</script>
