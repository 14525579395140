<template>
    <span>
        <div v-if="show">
            <v-tabs grow v-model="activeTab" color="accent" class="mb-4">
                <v-tab v-for="tab of tabs" :key="tab.id">
                    <v-badge
                        v-if="getBadge(tab) !== 0"
                        color="typography1"
                        inline
                        :content="getBadge(tab)"
                    >
                        {{ tab.name }}
                    </v-badge>
                    <span v-else> {{ tab.name }} </span>
                </v-tab>
            </v-tabs>

            <div
                :style="
                    'white-space: normal !important; overflow: auto; word-break: normal; min-height: 300px; height: ' +
                    height
                "
                class="pt-4"
            >
                <span v-show="activeTab === 0">
                    <span v-if="!duplicatedInfo">
                        <p class="text-h6 text--typography1">{{ calc.name }}</p>
                        <p
                            class="text-body-1 text--typography1"
                            style="word-break: normal !important"
                        >
                            {{ calc.description }}
                        </p>
                    </span>
                    <span v-if="calc.additionalInformationLinks">
                        <p class="text-h6 text--typography1">Links</p>
                        <div class="mb-4">
                            <div
                                v-for="link in calc.additionalInformationLinks"
                                :key="link"
                                class="text-body-1 text--typography1"
                            >
                                <a :href="link" target="_blank">{{ link }}</a>
                            </div>
                        </div>
                    </span>
                    <p class="text-h6 text--typography1">Information</p>
                    <p class="text-body-1 text--typography1">
                        Library version: {{ libraryVersion.version }}
                    </p>
                    <p
                        v-if="calc.user"
                        class="
                            text-body-1 text--typography1
                            d-flex
                            align-center
                        "
                    >
                        Created by: {{ calc.user.displayName }}
                        <v-btn
                            class="pa-0"
                            small
                            variant="plain"
                            density="compact"
                            icon
                            :href="'mailto:' + calc.user.mail"
                        >
                            <v-icon size="small">mdi-email</v-icon>
                        </v-btn>
                    </p>
                    <div class="d-flex" v-if="additionalAuthors.length > 0">
                        <p>Additional Authors:</p>
                        <ul>
                            <li
                                v-for="author in additionalAuthors"
                                :key="author"
                            >
                                {{ author }}
                            </li>
                        </ul>
                    </div>
                    <p class="text-body-1 text--typography1">
                        Last updated:
                        {{ new Date(libraryVersion.date).toDateString() }}
                    </p>
                    <p
                        class="text-body-1 text--typography1"
                        v-if="calc.persistentCalcID"
                    >
                        Persistent Calc ID: {{ calc.persistentCalcID }}
                    </p>
                    <p class="text-body-1 text--typography1">
                        Calc ID: {{ calc.calcId }}
                    </p>
                    <span
                        class="text-body-1 text--typography1"
                        v-if="calc.tags && calc.tags.length"
                    >
                        Tags:
                    </span>
                    <v-chip pill v-for="tag in calc.tags" :key="tag">
                        {{ tag }}
                    </v-chip>
                </span>

                <span v-show="activeTab === 1">
                    <span v-if="calc.changeLog">
                        <p class="text-h6 text--typography1">Changelog</p>
                        <div
                            v-for="(a, i) in calc.changeLog.slice().reverse()"
                            :key="i"
                        >
                            <p
                                class="text-subtitle-2"
                                style="margin-bottom: 5px"
                            >
                                {{ a.revision }}
                            </p>
                            <p>{{ a.description }}</p>
                        </div>
                    </span>
                    <span v-else-if="calc.revisions">
                        <p class="text-h6 text--typography1">Revisions</p>
                        <ul>
                            <li v-for="(a, i) in calc.revisions" :key="i">
                                v{{ a }}
                            </li>
                        </ul>
                    </span>
                    <p v-else class="text-body-1 text--typography1">
                        This calculation has no revisions.
                    </p>
                </span>

                <span v-show="activeTab === 2">
                    <p class="text-h6 text--typography1">Assumptions</p>
                    <p
                        v-if="!calc.assumptions"
                        class="text-body-1 text--typography1"
                    >
                        This calculation has no assumptions.
                    </p>
                    <ul v-if="calc.assumptions">
                        <li
                            v-for="(a, i) in calc.assumptions"
                            :key="i"
                            class="mt-2 mb-2 text-wrap"
                        >
                            {{ a }}
                        </li>
                    </ul>
                </span>

                <span v-show="activeTab === 3">
                    <span v-if="stats.users">
                        <div v-if="usageStatsError.info" style="color: red">
                            Error loading user data
                        </div>
                        <div v-if="usageStatsError.image" style="color: red">
                            Error loading user images
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">User</th>
                                        <th class="text-left">Times used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(obj, i) in allUserInfoImages"
                                        :key="i"
                                    >
                                        <td>
                                            <UserMenu
                                                :email="obj.email"
                                                :photo="obj.image"
                                                :user="obj.user"
                                            />
                                        </td>
                                        <td>{{ obj.count }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </span>
                    <span v-else>
                        This calculation has not been used yet.
                    </span>
                </span>
            </div>
        </div>
    </span>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RecordChip from '../records/RecordChip.vue'
import UserMenu from '../../UserMenu.vue'
export default {
    components: { RecordChip, UserMenu },
    props: {
        /**
         * calc to display info for
         */
        calc: {
            type: Object,
            required: true,
        },
        /**
         * Library version of the calc
         */
        libraryVersion: {
            type: Object,
            required: true,
        },
        /**
         * Whether the calc info is showing
         */
        show: {
            type: Boolean,
            default: true,
        },
        /**
         * show basic calc info - name & desc
         */
        duplicatedInfo: {
            required: false,
            default: false,
        },
        defaultTab: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            /**
             * Id of tab currently active/open
             */
            activeTab: this.defaultTab,
            /**
             * All tabs that are displayed
             * id and names
             */
            tabs: [
                { id: 0, name: 'Information' },
                { id: 1, name: 'Version History' },
                { id: 2, name: 'Assumptions' },
                { id: 3, name: 'Usage Stats' },
            ],
            /**
             * Stats of the calc
             */
            stats: {},
            /**
             * Array of all users images and info
             * {
             *      id: string;
             *      email: string;
             *      count: number;
             *      status: number;
             *      user: Object!;
             *      image: string!;
             * }[]
             */
            allUserInfoImages: [],
            /**
             * Contains whether there has been an error loading either the user images or user info
             * {
             *      info: boolean;
             *      image: boolean;
             * }
             */
            usageStatsError: {
                info: false,
                image: false,
            },
            /**
             * Whether the Usage Stats tab has been selected at some point
             */
            userInfoSelected: false,
        }
    },
    computed: {
        height() {
            if (this.activeTab == 3) {
                return '300px'
            }

            return '100%'
        },
        additionalAuthors() {
            return this.calc.additionalAuthors
                ? this.calc.additionalAuthors
                : []
        },
        ...mapState(['user']),
    },
    methods: {
        ...mapActions([
            'getUser',
            'getCalcStats',
            'getAllUserInfo',
            'getAllUserImages',
        ]),
        /**
         * Set the open tab
         * @param {String} name tab name
         */
        setTab(name) {
            this.tab = name
        },
        /**
         * Amount of records added onto poverall length
         * @param {Number} length length of records
         */
        amountOfRecords(length) {
            let amount = this.recordLength
            amount += length
            this.recordLength = amount
        },
        /**
         * Get badge for each tab
         * @param {Object} tab tab to get badge of
         */
        getBadge(tab) {
            switch (tab.id) {
                case 1:
                    return this.calc.revisions ? this.calc.revisions.length : 0
                case 2:
                    return this.calc.assumptions
                        ? this.calc.assumptions.length
                        : 0
                case 3:
                    return this.stats ? this.stats.total : 0
                default:
                    return 0
            }
        },
    },
    async mounted() {
        const [user, stats] = await Promise.all([
            this.getUser(this.calc.authorEmail),
            this.getCalcStats(this.calc.calcId),
        ])
        this.calc.user = user
        this.stats = stats
        this.allUserInfoImages = stats?.users?.map((u, i) => ({
            id: i + 1,
            email: u.name,
            count: u.count,
            image: undefined,
            user: undefined,
        }))
    },
    watch: {
        /**
         * If info not visible reset tabs to defaults
         */
        show(visible) {
            if (!visible) {
                this.activeTab = 0
            }
        },
        /**
         * If the tab goes to the Usage Stats, then load the user info
         */
        async activeTab(tab) {
            if (!this.userInfoSelected && tab === 3) {
                // check stats just in case
                if (!this.stats)
                    this.stats = await this.getCalcStats(this.calc.calcId)
                this.userInfoSelected = true
                // if the images request finishes before the user info request (unlikely) then the images will be added to this array
                let images = []

                let userInfo = false // whether the user info has been got yet
                // get the user info to show on hover
                this.getAllUserInfo(this.stats)
                    .then(async (res) => {
                        userInfo = true
                        this.allUserInfoImages = res

                        // if the images have already been got (unlikely), then add them to the array
                        if (images.length > 0) {
                            this.allUserInfoImages = res.map((r, i) => ({
                                ...r,
                                image: images[i],
                            }))
                        }
                    })
                    .catch((err) => {
                        this.usageStatsError.info = true
                        console.error(err)
                    })

                // get the user's images
                this.getAllUserImages(this.stats)
                    .then((res) => {
                        // if the user info has already been got (likely)
                        if (userInfo) {
                            this.allUserInfoImages = this.allUserInfoImages.map(
                                (a, i) => ({
                                    ...a,
                                    image:
                                        res[i].status === 200
                                            ? 'data:image/jpeg;base64,' +
                                              res[i].body
                                            : undefined,
                                })
                            )
                        } else {
                            // otherwise (unlikely) save the images to the images array
                            images = res.map((r) =>
                                r.status === 200
                                    ? 'data:image/jpeg;base64,' + r.body
                                    : undefined
                            )
                        }
                    })
                    .catch((err) => {
                        this.usageStatsError.image = true
                        console.error(err)
                    })
            }
        },
    },
}
</script>
