<template>
    <div style="width: 100%; align-self: start">
        <v-overlay :opacity="0.8" :value="loading">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <span v-if="!loading">
            <v-alert
                v-if="this.version.isPrerelease"
                color="blue darken-1"
                type="info"
                style="font-size: 1.25rem"
                class="d-flex justify-center sticky-alert"
            >
                <template v-slot:prepend>
                    <v-icon class="mr-4">mdi-information</v-icon>
                </template>
                {{
                    `You are working with a prerelease version of ${this.calc.libraryVersion.library.name} ${this.version.version}`
                }}
            </v-alert>
            <v-col cols="12" class="pa-0 ma-0">
                <v-row align="end">
                    <v-col cols="8">
                        <div>
                            <v-breadcrumbs
                                id="calc-bread"
                                divider="/"
                                class="ma-0 pa-0"
                                :items="getbreadcrumbs"
                            >
                                <v-breadcrumbs-item
                                    slot="item"
                                    slot-scope="{ item }"
                                    exact
                                    :to="{ path: item.href }"
                                    class="text-subtitle-1"
                                    style="color: primary !important"
                                    >{{ item.text }}</v-breadcrumbs-item
                                >
                            </v-breadcrumbs>
                        </div>
                    </v-col>

                    <v-col cols="4" align="end">
                        <v-btn
                            outlined
                            class="py-0 my-0 text-capitalize"
                            :to="{ path: finalBreadCrumb }"
                        >
                            <v-icon>mdi-chevron-left</v-icon> Back to browser
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-card
                v-if="!loading && !error"
                class="d-lg-flex mt-4"
                min-height="300px"
                width="100%"
            >
                <v-col cols="12" lg="5">
                    <div
                        class="
                            d-flex
                            flex-column
                            mt-10
                            ml-6
                            mr-6
                            align-start
                            justify-start
                        "
                    >
                        <span
                            class="
                                text-h4
                                font-weight-regular
                                typography1--text
                                mb-2
                            "
                        >
                            {{ calc.name }}
                        </span>

                        <CalcStatsChips
                            :calcId="calc.calcId"
                            :persistentCalcId="calc.persistentCalcID"
                            :noLeftMargin="true"
                        />

                        <span class="text-body-1 typography1--text mt-2">
                            {{ calc.description }}
                        </span>
                    </div>
                </v-col>

                <v-divider
                    vertical
                    inset
                    class="hidden-md-and-down"
                ></v-divider>
                <v-col cols="12" lg="7">
                    <div
                        class="d-flex flex-column mt-6 ml-6 mr-6 justify-start"
                    >
                        <CalcInfo
                            v-if="calc"
                            :calc="calc"
                            :libraryVersion="version"
                            :duplicatedInfo="true"
                            :defaultTab="defaultInfoTab"
                        />
                    </div>
                </v-col>
            </v-card>

            <v-card
                v-if="!loading"
                class="d-flex flex-column mt-4 pa-4"
                width="100%"
            >
                <v-card-title
                    class="text-h4 font-weight-regular typography1--text"
                >
                    Calculation method

                    <v-tabs v-model="activeTab" color="accent" class="mt-4">
                        <v-tab v-for="tab of tabs" :key="tab.id">
                            {{ tab.name }}
                        </v-tab>
                    </v-tabs>
                </v-card-title>

                <WebCalculate v-show="activeTab === 0" :calc="calc" />

                <template v-if="activeTab == 1">
                    <v-card-text class="text-body-1 typography1--text">
                        <v-col cols="12" md="8" lg="6" class="pa-0 ma-0">
                            <p>
                                All ArupCompute functions can be called from
                                Excel.
                            </p>
                            <p>
                                The
                                <a
                                    href="/docs/excel/"
                                    target="_blank"
                                    class="accent--text"
                                >
                                    documentation shows how to install the Excel
                                    plugin and how to use it.
                                </a>
                            </p>
                            <p>
                                The below snippet shows the formula name, and
                                what inputs are required.
                            </p>
                        </v-col>
                    </v-card-text>
                </template>

                <template v-if="activeTab == 2">
                    <v-card-text class="text-body-1 typography1--text">
                        <v-col cols="12" md="8" lg="6" class="pa-0 ma-0">
                            All ArupCompute functions can be called from Python.
                            The
                            <a
                                href="/docs/python/"
                                target="_blank"
                                class="accent--text"
                            >
                                documentation shows how to install the
                                arupcomputepy package and full usage
                                instructions
                            </a>
                            . The below snippet shows how to call this
                            calculation once.
                        </v-col>
                    </v-card-text>
                </template>

                <v-card dark v-if="activeTab != 0">
                    <v-container class="mx-5 my-0">
                        <v-row>
                            <v-col cols="auto" class="px-0">
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="
                                                copyCodeToClipboard(
                                                    activeTabName
                                                )
                                            "
                                            >mdi-content-copy</v-icon
                                        >
                                    </template>
                                    <span>Copy to clipboard</span>
                                </v-tooltip>
                            </v-col>

                            <v-col>
                                <v-card-text
                                    class="pa-0"
                                    v-if="activeTabName == 'excel'"
                                >
                                    {{ getExcelFormula }}
                                </v-card-text>
                                <v-card-text
                                    class="pa-0 text-pre-wrap"
                                    v-if="activeTabName == 'python'"
                                >
                                    {{ getPythonCode }}
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <template v-if="activeTab == 1">
                    <v-card-text class="text-body-1 typography1--text">
                        <v-col class="pa-0 mt-2">
                            Some ArupCompute functions have a lot of inputs and
                            outputs. To help set this up
                            <a
                                href="/docs/excel/how_to/calculation_templates.html"
                                target="_blank"
                                class="accent--text"
                            >
                                an automatic template can be inserted for any
                                ArupCompute function.
                            </a>
                        </v-col>
                    </v-card-text>
                </template>
            </v-card>
        </span>

        <!-- toast for copied to clipboard -->
        <v-snackbar v-model="snackbar">
            Copied to clipboard

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<style scoped>
.sticky-alert {
    position: sticky;
    top: 5rem;
    z-index: 5;
}
</style>

<script>
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import CalcInfo from './CalcInfo.vue'
import WebCalculate from './WebCalculate.vue'
import VueKatex from 'vue-katex'
import 'katex/dist/katex.min.css'
import CalcStatsChips from './CalcStatsChips.vue'
Vue.use(VueKatex)
export default {
    name: 'Calculation',
    components: {
        CalcInfo,
        WebCalculate,
        CalcStatsChips,
    },
    data() {
        return {
            /**
             * Selected calc
             */
            calc: {},
            /**
             * Library version of calc
             */
            version: {},
            /**
             * Controls whether page is loading
             */
            loading: true,
            /**
             * Page will not load fully if there is an error (most commonly due to the calc not existing)
             */
            error: false,
            /**
             * Tab id that is currently open/active
             */
            activeTab: 0,
            /**
             * All tabs, their id and name
             */
            tabs: [
                { id: 0, name: 'Web' },
                { id: 1, name: 'Excel' },
                { id: 2, name: 'Python' },
            ],
            snackbar: false,
        }
    },
    computed: {
        /**
         * Get all breadccrumbs from calcs API
         */
        getbreadcrumbs() {
            let items = []
            if (!this.calc.api) return items
            let apiarray = this.calc.api.split('/')
            for (var [index, value] of apiarray.entries()) {
                let link = `/library/${this.version.libraryId}?path=v${this.version.version}`
                if (index !== 0) {
                    link += `-${apiarray.slice(1, index + 1).join('-')}`
                }
                items.push({ text: value, href: link })
            }
            items.pop()
            return items
        },
        /**
         * Get the last breadcrumb to redirect back to treeview
         */
        finalBreadCrumb() {
            let crumbs = this.getbreadcrumbs
            if (crumbs.length) {
                return crumbs[crumbs.length - 1].href
            }
            return ''
        },
        /**
         * Get excel formula to display for calc
         */
        getExcelFormula() {
            let names = []
            for (var input of this.calc.inputs) names.push(input.name)
            let hash = this.calc.api.split('_').at(-1)
            return (
                '=' +
                this.calc.fullname.replace('+', '.') +
                '_' +
                hash +
                '(' +
                names.join(', ') +
                ')'
            )
        },
        /**
         * Get python code to display for calc
         */
        getPythonCode() {
            let lines = []
            lines.push('import arupcomputepy')
            lines.push('')
            lines.push("jobNumber = '00000000' # insert real job number")
            lines.push('connection = arupcomputepy.Connection(jobNumber)')
            lines.push('')
            lines.push('# ' + this.calc.name)
            lines.push('# ' + this.calc.description)
            lines.push('')
            if (this.calc.persistentCalcID) {
                lines.push(
                    "persistentCalcID = '" + this.calc.persistentCalcID + "'"
                )
            } else {
                lines.push('calcID = ' + this.calc.calcId)
            }
            lines.push('')
            lines.push('variables = {')

            let idx = 0
            for (var input of this.calc.inputs) {
                let variableLine = "    '" + input.name + "': value"
                if (idx != this.calc.inputs.length - 1)
                    variableLine = variableLine + ','
                variableLine = variableLine + ' # ' + input.description
                if (input.units) {
                    variableLine = variableLine + ' (' + input.units + ')'
                }
                lines.push(variableLine)
                idx = idx + 1
            }
            lines.push('}')
            lines.push('')
            if (this.calc.persistentCalcID) {
                lines.push(
                    'response = arupcomputepy.MakeCalculationRequest(connection, persistentCalcId=persistentCalcID, isBatch=False, variables=variables)'
                )
            } else {
                lines.push(
                    'response = arupcomputepy.MakeCalculationRequest(connection, calcID, isBatch=False, variables=variables)'
                )
            }
            lines.push('')

            if (this.calc.persistentCalcID) {
                lines.push(
                    '# We can also execute a calculation using its calc ID (ie. an ID that is specific to this library version):'
                )
                lines.push('# calcID = ' + this.calc.calcId)
                lines.push(
                    `# response = arupcomputepy.MakeCalculationRequest(connection, calcID, isBatch=False, variables=variables)`
                )
                lines.push('')
            }

            if (!(this.calc.outputs === undefined || this.calc.outputs == 0)) {
                lines.push('# Available output values:')
                for (var output of this.calc.outputs) {
                    let line = `${output.name} = response['${output.name}'] # ${output.description}`
                    if (output.units) {
                        line = line + ' (' + output.units + ')'
                    }
                    lines.push(line)
                }
            } else {
                lines.push('print(response)')
            }

            return lines.join('\n')
        },
        defaultInfoTab() {
            if (this.calc.assumptions) {
                return 2
            }
            return 0
        },
        activeTabName() {
            if (this.activeTab == 1) {
                return 'excel'
            }
            if (this.activeTab == 2) {
                return 'python'
            }
            return 'web'
        },
    },
    methods: {
        ...mapActions([
            'getCalc',
            'getLibraryVersion',
            'warmLibraryVersionByCalcId',
            'getCalcByPersistentId',
        ]),
        ...mapMutations(['SET_ALERT_ERRORS']),
        /**
         * Setup the page
         * @param {Function} calcfn the function to use for getting the calc. Should either be `setupWithCalcId` or `setupWithPersistentId` (or equivalent)
         * @param id calc id
         * @param {Object} query query contains the inputs
         * @param {Boolean} load whether to set page as loading
         */
        async pageSetup(calcfn, id, query, load) {
            if (load) this.loading = true
            let calc
            try {
                calc = await calcfn(id, query)
            } catch (err) {
                this.error = true
                this.loading = false
                return
            }
            if (calc.calcId === undefined) {
                this.SET_ALERT_ERRORS([
                    `The calc you're trying to use has either moved or been deleted. <a href="/docs/explanation/404.html" target="_blank">Refer to the documentation site for next steps.</a>`,
                ])
                this.error = true
                this.loading = false
                return
            }
            this.version = await this.getLibraryVersion(calc.libraryVersionId)
            this.calc = calc
            this.loading = false
        },
        async setupWithCalcId(id, query) {
            this.warmLibraryVersionByCalcId(id)
            return await this.getCalc({ id: id, query: query })
        },
        async setupWithPersistentId(id, query) {
            const version = {
                major:
                    this.$route.params.major !== undefined
                        ? this.$route.params.major
                        : -1,
                minor:
                    this.$route.params.minor !== undefined
                        ? this.$route.params.minor
                        : -1,
                patch:
                    this.$route.params.patch !== undefined
                        ? this.$route.params.patch
                        : -1,
            }
            return await this.getCalcByPersistentId({
                id: id,
                query: query,
                version: version,
            })
        },
        copyCodeToClipboard(method) {
            var text = this.getExcelFormula
            if (method == 'python') {
                text = this.getPythonCode
            }
            navigator.clipboard.writeText(text)
            this.snackbar = true
        },
    },
    async mounted() {
        let calc
        if (this.$route.params.calcId) {
            this.pageSetup(
                this.setupWithCalcId,
                this.$route.params.calcId,
                this.$route.query
            )
            calc = this.$route.params.calcId
        } else if (this.$route.params.persistentId) {
            this.pageSetup(
                this.setupWithPersistentId,
                this.$route.params.persistentId,
                this.$route.query
            )
            calc = this.$route.params.persistentId
        }

        // if the user came here from the function popularity table, posthog will have a value, send a posthog event
        if (this.$route.query.posthog) {
            // posthog may be turned off, so make sure that the posthog object exists before using it
            if (this.$posthog) {
                this.$posthog.capture(this.$route.query.posthog, {
                    calc,
                })
            }
        }
    },
    watch: {
        async $route(to, from) {
            if (
                to.name === 'calculation' ||
                to.name.includes('persistentCalcId')
            ) {
                if (
                    to.name === 'calculation' &&
                    parseInt(to.params.calcId) !== parseInt(from.params.calcId)
                ) {
                    this.loading = true

                    await this.pageSetup(
                        this.setupWithCalcId,
                        to.params.calcId,
                        to.query,
                        true
                    )
                } else if (
                    to.name.includes('persistentCalcId') &&
                    to.params.persistentId !== from.params.persistentId
                ) {
                    this.loading = true

                    await this.pageSetup(
                        this.setupWithPersistentId,
                        to.params.persistentId,
                        to.query,
                        true
                    )
                } else if (
                    JSON.stringify(to.query) !== JSON.stringify(from.query)
                ) {
                    await this.pageSetup(
                        to.name === 'calculation'
                            ? this.setupWithCalcId
                            : this.setupWithPersistentId,
                        to.name === 'calculation'
                            ? to.params.calcId
                            : to.params.persistentId,
                        to.query,
                        false
                    )
                }
            }
        },
    },
}
</script>
