<template>
    <v-dialog :value="value" @input="$emit('input')" max-width="1000px">
        <v-card :class="json ? 'code-json' : 'code-report'">
            <v-card-title primary-title>
                <span v-if="this.json" class="mb-2 typography1--text text-h4">
                    JSON API output
                </span>
            </v-card-title>
            <v-card-text>
                <vue-json-pretty
                    v-if="json"
                    :data="body"
                    :highlightMouseoverNode="true"
                    :showLength="true"
                    :deep="3"
                ></vue-json-pretty>

                <span v-if="report">
                    <h2 v-if="error">Errors</h2>
                    <h2 v-if="warning">Warnings</h2>
                    <h2 v-if="remark">Remarks</h2>
                    <span
                        v-if="error || warning || remark"
                        :class="`${textColor}--text`"
                    >
                        <p v-for="(message, i) in body" :key="i">
                            {{ message }}
                        </p>
                    </span>
                    <span v-else>
                        <span
                            v-if="isKatex(newBody)"
                            v-katex:auto="{ options: katexOptions }"
                            v-html="newBody"
                        />
                        <span v-else id="mathjax" v-html="newBody"></span>
                    </span>
                </span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click.native="$emit('input')">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {
    components: {
        VueJsonPretty,
    },
    props: {
        /**
         * Whether displaying errors
         */
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Whether displaying warnings
         */
        warning: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Whether displaying remarks
         */
        remark: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Whether displaying json
         */
        json: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Whether it's a report and not json
         */
        report: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Body to display
         */
        body: {
            required: true,
        },
        /**
         * Value for dialog control
         */
        value: {},
    },
    data() {
        return {
            /**
             * NewBody to edit the original props
             */
            newBody: this.body,
            /**
             * Katex math options for display
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
        }
    },
    computed: {
        textColor() {
            if (this.error) {
                return 'red'
            } else if (this.warning) {
                return 'orange'
            } else if (this.remark) {
                return 'blue'
            }
        },
    },
    mounted() {
        if (this.report) {
            if (this.newBody && this.newBody.arupComputeReport_HTML)
                this.newBody = this.newBody.arupComputeReport_HTML
            //legacy output
            else if (this.newBody.verbose) this.newBody = this.newBody.verbose
            else
                this.newBody = "This calculation does not have a report (ง'̀-'́)ง"
        }
    },
    methods: {
        isKatex(text) {
            if (typeof text !== 'string') return false
            return text.includes('$$')
        },
    },
}
</script>

<style>
.code-json code {
    border: none;
    box-shadow: none;
}
</style>
