<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title>
                <span
                    v-if="calcRecord.calc.name && showCalcInfo"
                    class="font-weight-bold"
                    >{{ calcRecord.calc.name }}:
                </span>
                <span
                    v-katex:auto="{ options: katexOptions }"
                    v-if="calcRecord.isSingleResults"
                    v-html="calcRecord.output.result"
                ></span>
                <span v-else v-katex:auto="{ options: katexOptions }">{{
                    resultString
                }}</span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="calcRecord.calc.name">
                <span v-if="showCalcInfo">{{ calcRecord.calc.api }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
                <span>{{ friendlyQuery() }}</span>
            </v-list-item-subtitle>
        </v-list-item-content>

        <!-- ERROR WARNINGS REMARKS -->
        <v-list-item-action
            v-if="
                calcRecord.output.errors && calcRecord.output.errors.length > 0
            "
        >
            <Dialog
                :report="true"
                :error="true"
                :body="calcRecord.output.errors"
                v-model="reportErrors"
            />
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        ripple
                        v-on="on"
                        @click.stop="reportErrors = true"
                    >
                        <v-icon color="#ff5252 ">mdi-alert-octagon</v-icon>
                    </v-btn>
                </template>
                <span
                    >{{ calcRecord.output.errors.length }} error{{
                        calcRecord.output.errors.length > 1 ? 's' : ''
                    }}</span
                >
            </v-tooltip>
        </v-list-item-action>
        <v-list-item-action
            v-if="
                calcRecord.output.warnings &&
                calcRecord.output.warnings.length > 0
            "
        >
            <Dialog
                :report="true"
                :warning="true"
                :body="calcRecord.output.warnings"
                v-model="reportWarnings"
            />
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        ripple
                        v-on="on"
                        @click.stop="reportWarnings = true"
                    >
                        <v-icon color="#ffc107">mdi-alert</v-icon>
                    </v-btn>
                </template>
                <span
                    >{{ calcRecord.output.warnings.length }} warning{{
                        calcRecord.output.warnings.length > 1 ? 's' : ''
                    }}</span
                >
            </v-tooltip>
        </v-list-item-action>
        <v-list-item-action
            v-if="
                calcRecord.output.remarks &&
                calcRecord.output.remarks.length > 0
            "
        >
            <Dialog
                :report="true"
                :remark="true"
                :body="calcRecord.output.remarks"
                v-model="reportRemarks"
            />
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        ripple
                        v-on="on"
                        @click.stop="reportRemarks = true"
                    >
                        <v-icon color="#2196f3">mdi-information</v-icon>
                    </v-btn>
                </template>
                <span>
                    {{ calcRecord.output.remarks.length }} remark{{
                        calcRecord.output.remarks.length > 1 ? 's' : ''
                    }}
                </span>
            </v-tooltip>
        </v-list-item-action>
        <!-- QUICK REPORT -->
        <v-list-item-action v-if="calcRecord.output.arupComputeReport_HTML">
            <Dialog
                :report="true"
                :body="calcRecord.output"
                v-model="quickReport"
            />
            <v-btn
                class="text-capitalize button"
                rounded
                small
                @click.stop="quickReport = true"
            >
                Report
                <v-icon class="icon" small color="secondary"
                    >mdi-text-long</v-icon
                >
            </v-btn>
        </v-list-item-action>
        <!--PDF PRINT-->
        <v-list-item-action v-if="calcRecord.output.arupComputeReport_HTML">
            <v-dialog v-model="dialog" width="750">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="text-capitalize button"
                        rounded
                        small
                        v-bind="attrs"
                        v-on="on"
                    >
                        PDF
                        <v-icon class="icon" small color="secondary"
                            >mdi-file-pdf-box</v-icon
                        >
                    </v-btn>
                </template>
                <PdfDialog
                    :html="calcRecord.output.arupComputeReport_HTML"
                    :calcname="calcRecord.calc.fullname"
                />
            </v-dialog>
        </v-list-item-action>
        <!--HTML PRINT-->
        <v-list-item-action v-if="calcRecord.output.arupComputeReport_HTML">
            <v-btn
                class="text-capitalize button"
                rounded
                small
                @click.stop="genhtml(calcRecord.output)"
            >
                HTML
                <v-icon class="icon" small color="secondary"
                    >mdi-language-html5</v-icon
                >
            </v-btn>
        </v-list-item-action>
        <!--JSON OUTPUT-->
        <v-list-item-action>
            <Dialog
                :json="true"
                :body="calcRecord.output"
                v-model="jsonDialog"
            />
            <v-btn
                class="text-capitalize button json-icon"
                rounded
                small
                @click.stop="jsonDialog = true"
            >
                JSON <span class="icon">{}</span>
            </v-btn>
        </v-list-item-action>
        <!--SHARE-->
        <v-list-item-action>
            <ShareDialog :record="calcRecord" v-model="shareDialog" />
            <v-btn
                class="text-capitalize button"
                rounded
                small
                @click.stop="shareDialog = true"
            >
                Share
                <v-icon class="icon" small color="secondary"
                    >mdi-share-variant</v-icon
                >
            </v-btn>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
import { mapActions } from 'vuex'
import Dialog from './Dialog.vue'
import ShareDialog from './ShareDialog.vue'
import PdfDialog from './PdfDialog.vue'
import 'katex/dist/katex.min.css'
export default {
    components: { Dialog, ShareDialog, PdfDialog },
    props: {
        /**
         * calc record - result of the calc
         */
        calcRecord: {
            type: Object,
            required: true,
        },
        /**
         * Show info of calc if not single result
         */
        showCalcInfo: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            /**
             * Options for katex math display
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
            /**
             * Controls if dialog is shown for JSON
             */
            jsonDialog: false,
            /**
             * Controls if dialog is shown for sharing
             */
            shareDialog: false,
            /**
             * Controls if dialog is shown for quick report
             */
            quickReport: false,
            /**
             * Controls if reports are shown for errors
             */
            reportErrors: false,
            /**
             * Controls if dialog is shown for warnings
             */
            reportWarnings: false,
            /**
             * Controls if dialog is shown for remarks
             */
            reportRemarks: false,
        }
    },
    computed: {
        /**
         * Result string if not showing calc info
         */
        resultString() {
            var short = []
            if (!this.calcRecord.output.arupComputeResultItems) return ''
            for (var r in this.calcRecord.output.arupComputeResultItems) {
                let results = this.calcRecord.output.arupComputeResultItems
                //is object
                // eslint-disable-next-line no-prototype-builtins
                if (results.hasOwnProperty(r)) {
                    short.push(
                        this.stringifyResult(
                            results[r].html,
                            results[r].units,
                            results[r].symbol,
                            results[r].value
                        )
                    )
                }
                //is array
                else {
                    short.push(
                        this.stringifyResult(r.html, r.units, r.symbol, r.value)
                    )
                }
            }
            return short.join(', ')
        },
    },
    methods: {
        ...mapActions(['printHtml']),
        async genhtml() {
            this.calcRecord.showProgress = true
            this.printHtml(this.calcRecord)
            this.calcRecord.showProgress = false
        },
        /**
         * Make query friendly - nice display
         */
        friendlyQuery() {
            return this.calcRecord.query.replace('?', '').split('&').join(', ')
        },
        /**
         * Stringify the result
         */
        stringifyResult(html, units, symbol, value) {
            if (html != null) return html
            else {
                let u = units ? ' ' + units : ''
                return symbol + ' = ' + value + u
            }
        },
    },
}
</script>
<style>
.json-icon {
    font-family: monospace;
    font-weight: 900;
    font-size: 20px;
}
.v-list-item__action {
    align-self: center !important;
}
.button {
    margin-left: 10px;
    margin-bottom: 5px;
}
.icon {
    margin-left: 5px;
}

.v-list-item__action {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
</style>
