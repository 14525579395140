<template>
    <v-dialog :value="value" width="800px" @input="$emit('input')">
        <v-card>
            <v-card-title>
                <span class="mb-2 typography1--text text-h4">
                    Share via link
                </span>
            </v-card-title>
            <v-card-text>
                <v-layout row>
                    <v-text-field
                        :value="fullurl()"
                        class="share-input"
                        label="URL"
                        underlined
                        readonly
                    ></v-text-field>
                    <v-btn
                        id="calc-btn"
                        color="black"
                        text
                        fab
                        @click.native.stop="copyToClipboard(fullurl())"
                    >
                        <v-icon color="secondary">mdi-content-copy</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click.native="$emit('input')">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['record', 'value'],
    data() {
        return {}
    },
    methods: {
        fullurl: function () {
            if (this.record.calc.persistentCalcID) {
                const calc = this.record.calc
                return `${window.location.origin}/persistentCalcs/${
                    calc.persistentCalcID
                }/${calc.major}/${calc.minor}/${calc.patch}?${this.getParams()}`
            } else {
                return `${window.location.origin}/calcs/${
                    this.record.calcId
                }?${this.getParams()}`
            }
        },
        getParams() {
            let string = ''
            for (let param in this.record.body) {
                let val = this.record.body[param]

                if (Array.isArray(val) || val === Object(val))
                    val = JSON.stringify(val)

                let valUrl = encodeURIComponent(val)
                string += `${param}=${valUrl}&`
            }
            return string.substring(0, string.length - 1)
        },
        copyToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text)
                return
            }
            navigator.clipboard.writeText(text).then(
                function () {
                    console.log('Async: Copying to clipboard was successful!')
                },
                function (err) {
                    console.error('Async: Could not copy text: ', err)
                },
                this.$emit('input')
            )
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement('textarea')
            textArea.value = text
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                var successful = document.execCommand('copy')
                var msg = successful ? 'successful' : 'unsuccessful'
                console.log('Fallback: Copying text command was ' + msg)
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
            }
            document.body.removeChild(textArea)
            this.$emit('input')
        },
    },
}
</script>

<style>
.share-input i--click::after {
    animation: anim-effect-boris 0.3s forwards;
    background-color: red;
}
@keyframes anim-effect-boris {
    0% {
        transform: scale3d(0.3, 0.3, 1);
    }
    25%,
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale3d(1.2, 1.2, 1);
    }
}
</style>
