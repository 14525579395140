var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"text-caption text--secondary d-inline-block text-truncate mt-0 mb-0 pl-3",staticStyle:{"max-width":"100%"}},[_vm._v(_vm._s(_vm.inputDetails.description)+" ")]),(_vm.isEnum(_vm.inputDetails))?_c('v-select',{attrs:{"color":"secondary","filled":"","items":_vm.inputDetails.values,"required":"","rules":[(v) => !!v || _vm.inputDetails.name + ' is required'],"menu-props":{
                    closeOnContentClick: true,
                    closeOnClick: true,
                    offsetY: true,
                }},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):(_vm.isBool(_vm.inputDetails))?_c('v-select',{attrs:{"color":"secondary","filled":"","items":_vm.boolOptions,"required":"","rules":[(v) => !!v || _vm.inputDetails.name + ' is required'],"menu-props":{
                    closeOnContentClick: true,
                    closeOnClick: true,
                    offsetY: true,
                }},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_c('v-text-field',{staticClass:"acInput",attrs:{"color":"secondary","filled":"","required":"","rules":[
                    (v) => !!v || _vm.inputDetails.name + ' is required',
                    (v) =>
                        _vm.validInput(_vm.inputDetails) ||
                        _vm.inputDetails.name + ' is incorrectly formatted',
                ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.isComplexInput(_vm.inputDetails))?_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/docs/explanation/JSON.html","target":"_blank"}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-code-json")])],1)]}}],null,true)},[_vm._v(" JSON input ")]):(_vm.inputDetails.units)?_c('katex-element',{attrs:{"expression":_vm.escapeUnits(_vm.inputDetails.units)}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getUnits(_vm.inputDetails.description))}})]},proxy:true}],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},[_c('template',{slot:"label"},[(_vm.inputDetails.latex)?_c('katex-element',{attrs:{"expression":_vm.inputDetails.latex}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getGreekName(_vm.inputDetails.name))}})],1)],2)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.inputDetails.description))])])
}
var staticRenderFns = []

export { render, staticRenderFns }