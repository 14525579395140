<template>
    <div>
        <div class="text-h5 font-weight-bold">{{ inputGroup.title }}</div>
        <p class="text-subtitle-2 text--secondary">
            <CalcTextContainer>
                {{ inputGroup.description }}
            </CalcTextContainer>
        </p>
        <CalcInputContainer :calc="inputGroup">
            <v-flex
                v-for="(input, index) in inputGroup.inputs"
                :key="index"
                xs12
                sm6
                md3
                pr-5
            >
                <CalcInput v-model="input.value" :input-details="input" />
            </v-flex>
        </CalcInputContainer>
    </div>
</template>
<script>
import CalcInput from './CalcInput.vue'
import CalcInputContainer from './CalcInputContainer.vue'
import CalcTextContainer from './CalcTextContainer.vue'
export default {
    components: { CalcInput, CalcInputContainer, CalcTextContainer },
    props: {
        inputGroup: {
            type: Object,
        },
    },
}
</script>
