var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('div',{attrs:{"id":"resultBox"}},[_c('br'),(_vm.showProgressResults)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"accent"}}):_vm._e(),(_vm.calcRecords.length > 0)?_c('div',[_c('v-list',{attrs:{"three-line":""}},[(!_vm.showCalcInfo)?_c('v-subheader',[_vm._v("Results")]):_vm._e(),_vm._l((_vm.calcRecords),function(calcRecord){return _c('v-list-group',{key:calcRecord.calcRecordId},[_c('div',{staticStyle:{"width":"100%"},attrs:{"slot":"activator"},on:{"mouseover":function($event){calcRecord.mouseOver = true},"mouseleave":function($event){calcRecord.mouseOver = false}},slot:"activator"},[(calcRecord.showProgress)?_c('v-progress-linear',{staticClass:"ma-0",attrs:{"indeterminate":true}}):_vm._e(),_c('Result',{attrs:{"calcRecord":calcRecord,"showCalcInfo":_vm.showCalcInfo}})],1),(
                            !calcRecord.isSingleResults && calcRecord.output
                        )?_c('div',_vm._l((calcRecord.output
                                .arupComputeResultItems),function(r,i){return _c('v-list-item',{key:i},[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(r.html)?_c('span',{directives:[{name:"katex",rawName:"v-katex:auto",value:({
                                            options: _vm.katexOptions,
                                        }),expression:"{\n                                            options: katexOptions,\n                                        }",arg:"auto"}],domProps:{"innerHTML":_vm._s(r.html)}}):_c('span',[_c('span',{directives:[{name:"katex",rawName:"v-katex:auto",value:({
                                                options: _vm.katexOptions,
                                            }),expression:"{\n                                                options: katexOptions,\n                                            }",arg:"auto"}],domProps:{"innerHTML":_vm._s(_vm.getKaTeX(r).html)}})])]),_c('v-list-item-subtitle',[_c('p',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.getKaTeX(r).description)+" ")])])],1)],1)}),1):_c('div',[_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                                            calcRecord && calcRecord.output
                                        )?_c('span',[_vm._v("Result: "+_vm._s(calcRecord.output.result))]):_vm._e()])],1)],1)],1)])})],2)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }