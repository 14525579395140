<template>
    <v-card class="mb-5">
        <v-card-text class="pr-2 pl-4 pb-2 pt-4">
            <v-row style="margin: 0 !important">
                <v-col cols="2" class="pl-0 d-flex align-center pt-0">
                    <v-img
                        :src="require('../../../assets/calcBlocks/1.png')"
                    ></v-img>
                </v-col>
                <v-col cols="10" class="pa-0 pt-0">
                    <v-row style="margin: 0 !important">
                        <v-col sm="8" md="8" lg="6" cols="6" class="pb-0 pt-0">
                            <div
                                class="pb-4 pt-2 d-flex"
                                style="
                                    font-size: 1.5rem;
                                    font-weight: 400 !important;
                                    overflow-wrap: break-word;
                                    word-break: normal;
                                    color: black;
                                "
                            >
                                <v-icon class="mr-2">
                                    mdi-vector-polyline mdi-rotate-90
                                </v-icon>
                                <div>
                                    {{ title }}
                                </div>
                            </div>
                            <div
                                class="red--text pb-4"
                                style="
                                    font-size: 1rem;
                                    font-weight: 300 !important;
                                "
                            >
                                v{{ version }} (latest version)
                            </div>
                        </v-col>
                        <v-col
                            sm="4"
                            md="4"
                            lg="6"
                            class="
                                d-flex
                                justify-end
                                align-start
                                pr-0
                                pb-0
                                pt-0
                            "
                        >
                            <v-lazy v-model="isStatsActive">
                                <CalcStatsChips
                                    class="pr-2"
                                    v-if="showStats && stats.hasStats"
                                    :moveToEnd="true"
                                    :no-left-margin="true"
                                    :manually-set-vals="true"
                                    :disable-clickable="true"
                                    :manual-loading="stats.loading"
                                    :manual-likes="stats.likes.toString()"
                                    :manual-total-projects="
                                        stats.numberOfProjects.toString()
                                    "
                                    :manual-total-usage="
                                        stats.totalFunctionCalls.toString()
                                    "
                                    :manual-unique-users="
                                        stats.totalUsers.toString()
                                    "
                                />
                            </v-lazy>
                        </v-col>
                    </v-row>
                    <v-row style="margin: 0 !important">
                        <v-col cols="10" class="pt-0">
                            <div
                                style="
                                    font-size: 1rem;
                                    font-weight: 300 !important;
                                    color: black;
                                "
                            >
                                {{ description }}
                            </div>
                        </v-col>
                        <v-col
                            cols="2"
                            class="d-flex justify-end align-end pr-0"
                        >
                            <v-btn
                                class="text-capitalize button"
                                rounded
                                small
                                outlined
                                :href="href"
                            >
                                Open
                                <v-icon class="icon" color="secondary">
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CalcStatsChips from '../calc/CalcStatsChips.vue'

export default {
    props: ['library'],
    components: { CalcStatsChips },
    data() {
        return {
            stats: {
                hasStats: true,
                loading: true,
                numberOfProjects: 0,
                likes: 0,
                totalFunctionCalls: 0,
                totalUsers: 0,
            },
            isStatsActive: false,
        }
    },
    computed: {
        ...mapState(['showStats']),
        href() {
            return `/library/${this.library.libraryId}?path=latest`
        },
        title() {
            return this.library.name
        },
        libraryVersion() {
            return this.library.libraryVersions[0]
        },
        version() {
            return this.libraryVersion.version
        },
        description() {
            return this.libraryVersion.description
        },
    },
    watch: {
        isStatsActive: async function (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.getLibrariesStats([this.library.libraryId]).then((res) => {
                    if (
                        res.length > 0 &&
                        res[0].numberOfProjects != undefined
                    ) {
                        const val = res[0]
                        this.stats.likes = this.compact(val.likes)
                        this.stats.numberOfProjects = this.compact(
                            val.numberOfProjects
                        )
                        this.stats.totalFunctionCalls = this.compact(
                            val.totalFunctionCalls
                        )
                        this.stats.totalUsers = this.compact(val.totalUsers)
                    } else {
                        this.stats.hasStats = false
                    }
                    this.stats.loading = false
                })
            }
        },
    },
    methods: {
        ...mapActions(['getLibrariesStats']),
        compact(number) {
            let formatter = Intl.NumberFormat('en', {
                notation: 'compact',
                compactDisplay: 'short',
            })
            let formatted = formatter.format(number)
            return formatted === 'NaN' ? '0' : formatted
        },
    },
}
</script>
