import posthog from 'posthog-js'

export default {
    install(Vue, options) {
        Vue.prototype.$posthog = posthog.init(process.env.VUE_APP_POSTHOG, {
            api_host: process.env.VUE_APP_POSTHOG_URL,
            autocapture: false,
            capture_pageview: true,
            disable_session_recording: true,
        })
    },
}
