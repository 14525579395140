<template>
    <v-card class="mb-5">
        <v-card-title>
            <v-row style="width: 100%">
                <v-col cols="7" class="d-flex flex-nowrap align-center">
                    <v-icon class="mr-2">
                        mdi-vector-polyline mdi-rotate-90
                    </v-icon>
                    <span
                        style="
                            font-size: 1.5rem;
                            font-weight: 400 !important;
                            overflow-wrap: break-word;
                            word-break: normal;
                        "
                        >{{ title }}</span
                    >
                </v-col>
                <v-col cols="5">
                    <CalcStatsChips
                        :calcId="calcId"
                        style="min-width: 0"
                        :moveToEnd="true"
                    />
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
            <div
                style="
                    color: black;
                    font-size: 1rem;
                    font-weight: 300 !important;
                "
            >
                {{ description }}
            </div>
            <div
                class="pt-2"
                style="
                    color: '#767676';
                    font-size: 1rem;
                    font-weight: 300 !important;
                "
            >
                Inputs: {{ inputText }}
            </div>
        </v-card-text>
        <v-card-actions class="pl-4">
            <span
                class="text-h6"
                style="
                    font-size: 1rem !important;
                    font-weight: 400 !important;
                    overflow-wrap: break-word;
                    word-break: normal;
                "
                v-html="folderPath"
            ></span>
            <v-spacer></v-spacer>
            <v-btn
                class="text-capitalize button"
                rounded
                small
                outlined
                @click="switchInfoShow"
            >
                Info
                <v-icon class="icon" color="secondary">
                    mdi-information-outline
                </v-icon>
            </v-btn>
            <v-btn
                class="text-capitalize button"
                rounded
                small
                outlined
                :href="calcHref"
            >
                Open
                <v-icon class="icon" color="secondary">
                    mdi-chevron-right
                </v-icon>
            </v-btn>
        </v-card-actions>
        <v-expand-transition>
            <div v-show="showInfo" class="pa-4">
                <CalcInfo :calc="calc" :libraryVersion="libraryVersion" />
            </div>
        </v-expand-transition>
    </v-card>
</template>
<script>
import CalcStatsChips from '../calc/CalcStatsChips.vue'
import CalcInfo from '../calc/CalcInfo.vue'

export default {
    components: { CalcStatsChips, CalcInfo },
    props: ['calc'],
    data() {
        return {
            showInfo: false,
        }
    },
    methods: {
        switchInfoShow() {
            this.showInfo = !this.showInfo
        },
    },
    computed: {
        libraryVersion() {
            return this.calc.libraryVersion
        },
        title() {
            return this.calc.name
        },
        calcId() {
            return this.calc.calcId
        },
        description() {
            return this.calc.description
        },
        inputs() {
            return this.calc.inputs
        },
        folderPath() {
            let api = this.calc.api
            let splitted = api.split('/')
            let len = splitted.length
            splitted[len - 1] = this.calc.name
            return splitted.join('<wbr>/<wbr>')
        },
        inputText() {
            if (this.inputs.length == 0) {
                return 'No inputs'
            }

            let idx = 0
            let line = ''
            for (var input of this.inputs) {
                line = line + input.name
                if (idx != this.inputs.length - 1) {
                    line = line + ', '
                }
                idx = idx + 1
            }
            return line
        },
        calcHref() {
            if (this.calc.persistentCalcID)
                return `/persistentCalcs/${this.calc.persistentCalcID}`
            else return `/calcs/${this.calc.calcId}`
        },
    },
}
</script>
