<template>
    <v-container style="height: 100%">
        <v-row v-if="loading">
            <v-col>
                <v-overlay :opacity="0.8" :value="loading">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-spacer></v-spacer>
            <v-col :cols="mainCols">
                <div
                    class="
                        text-h2 text-left
                        font-weight-regular
                        typography1--text
                    "
                    style="padding-bottom: 20px"
                >
                    Calculation Browser
                </div>
                <div v-if="!loading" style="align-self: start">
                    <v-card>
                        <v-card-title class="typography1--text">
                            {{ library.name }}
                            <v-btn
                                disabled
                                outlined
                                x-small
                                v-if="
                                    items.length &&
                                    items[0].libraryVersionStatusId
                                "
                                class="text-capitalize ml-4 px-4"
                            >
                                {{ getLibCategory }}
                            </v-btn>
                        </v-card-title>
                        <v-card-subtitle
                            v-if="items.length && items[0].description"
                            class="typography1--text"
                        >
                            {{ items[0].description }}
                        </v-card-subtitle>
                    </v-card>
                    <Treeview
                        v-if="items.length > 0 && !noCalcsError"
                        :items="items"
                        :firstOpen="open"
                        :url="true"
                        :libraryVersions="allVersions"
                        :firstShow="show"
                        :latest="latest"
                        :calcs="calcs"
                    />
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CalcInfo from './calc/CalcInfo.vue'
import helpers from '../../helpers'
import Treeview from '../Treeview.vue'

export default {
    name: 'libraryBrowser',
    components: {
        CalcInfo,
        Treeview,
    },
    data() {
        return {
            /**
             * Library to browse
             */
            library: [],
            /**
             * All versions of the library
             */
            allVersions: [],
            /**
             * Items to go into the treeview
             */
            items: [],
            /**
             * Whether page is loading
             */
            loading: false,
            /**
             * Array of open folder ids
             */
            open: [],
            /**
             * Dict of calcIds with Boolean values
             * Controls which calc infos are open
             */
            show: {},
            /**
             * whether the user is on the latest version of the library. Will attempt to use persistent calc id's if latest===true
             */
            latest: false,
            /**
             * Array of all the calcs in the library version
             */
            calcs: [],
            noCalcsError: false,
        }
    },
    computed: {
        ...mapState(['libCategories']),
        getLibCategory() {
            return this.libCategories[this.items[0].libraryVersionStatusId]
        },
        mainCols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 12
                case 'sm':
                    return 12
                case 'md':
                    return 12
                case 'lg':
                    return 8
            }

            return 7
        },
    },
    methods: {
        ...mapActions([
            'getLibraryVersions',
            'getVersionCalc',
            'getLibrary',
            'warmLibraryVersionByLibVerId',
        ]),
        ...mapMutations(['SET_ALERT_ERRORS']),
        /**
         * Gets the library version for the calc
         * @param {Object} calc the selected calc
         */
        getLibraryVersion(calc) {
            return this.allVersions.find(
                (vers) => vers.libraryVersionId === calc.libraryVersionId
            )
        },
        /**
         * Set the open folders from the URL path
         * @param {Array} versions versions that are in the treeview
         */
        setOpen(versions) {
            if (this.$route.query.path) {
                let open = []
                let route = this.$route.query.path
                let parts = route.split('-')
                open.push(versions[0].id)
                if (parts.length > 1 && parts[1].includes('beta')) {
                    parts = parts.splice(1, 1)
                }
                let path = ''
                for (let i = 1; i < parts.length; i++) {
                    let libraryName = this.library.name
                    if (libraryName.includes(' '))
                        libraryName = libraryName.replaceAll(' ', '')
                    path += '/' + parts[i]
                    let id =
                        `n-v${versions[0].libraryVersionId}-${libraryName}${path}`.toLowerCase()
                    open.push(id)
                }
                this.open = open
            }
        },
        /**
         * Only shows the version in the URL path in treeview
         * @param {Array} allVersions all library versions
         */
        showRightVersion(allVersions) {
            if (this.$route.query.path) {
                let route = this.$route.query.path
                let parts = route.split('-')
                let version = parts[0]
                let latestVersion = [allVersions.find((v) => !v.isPrerelease)]
                if (version === 'latest') {
                    this.latest = true
                    return latestVersion
                } else if (version.charAt(0) === 'v') {
                    version = version.substring(1)
                }
                if (parts.length > 1 && parts[1].includes('beta')) {
                    version += `-${parts[1]}`
                }
                let selectedVersion = [
                    allVersions.find((v) => v.version === version),
                ]
                this.latest =
                    latestVersion[0].version === selectedVersion[0].version
                return selectedVersion
            }
            return allVersions
        },
        /**
         * Loads children of library version
         * @param {Object} vers library version
         */
        async loadChildren(vers) {
            let calcs = []
            await this.getVersionCalc(vers.libraryVersionId).then(
                (response) => {
                    calcs = response
                }
            )

            if (calcs.length > 0) {
                this.calcs = calcs
                this.noCalcsError = false
                vers.children = helpers.buildTree(calcs)
                helpers.addCount(vers)
                calcs.forEach((calc) => {
                    this.show[calc.calcId] = false
                })
                vers.children.forEach((node) => {
                    helpers.addCount(node)
                })
                return vers
            } else {
                this.noCalcsError = true
                this.SET_ALERT_ERRORS([
                    `The library version you're trying to use has no calcs. Please either check with the library owner, or ensure the library was uploaded in the right format.`,
                ])
            }
        },
        /**
         * Updates the page
         * @param libraryId
         */
        async updatePage(libraryId) {
            this.loading = true
            this.library = await this.getLibrary(libraryId)
            let versions = await this.getLibraryVersions(libraryId)
            let chosenVersions = this.showRightVersion(versions)
            if (
                chosenVersions.length === 1 &&
                chosenVersions[0] === undefined
            ) {
                this.allVersions = []
                this.loading = false
                this.SET_ALERT_ERRORS([
                    `The library version you're trying to use has either moved or been deleted. <a href="/docs/explanation/404.html" target="_blank">Refer to the documentation site for next steps.</a>`,
                ])
                return
            }
            let items = []
            chosenVersions.forEach((vers) => {
                vers.id = `v${vers.version}`
                vers.name = `v${vers.version}`
                vers.children = []
                vers.chip = vers.isPrerelease ? vers.suffix : 'stable'
                items.push(vers)
            })
            if (
                chosenVersions.find((v) => !v.isPrerelease) &&
                versions.find((v) => !v.isPrerelease).version ===
                    chosenVersions.find((v) => !v.isPrerelease).version
            )
                chosenVersions.find((v) => !v.isPrerelease).chip =
                    'latest stable'
            if (chosenVersions.length === 1)
                this.items = await this.loadChildren(chosenVersions[0])
            this.items = items
            this.setOpen(chosenVersions)
            this.warmLibraryVersionByLibVerId(
                chosenVersions[0].libraryVersionId
            )
            this.allVersions = versions
            this.loading = false
        },
    },
    watch: {
        /**
         * Used to update page when URL params change
         */
        async $route(to, from) {
            if (to.name === 'libraryBrowser') {
                if (
                    parseInt(to.params.libraryId) !==
                    parseInt(from.params.libraryId)
                ) {
                    await this.updatePage(to.params.libraryId)
                }
            }
        },
    },
    async mounted() {
        await this.updatePage(this.$route.params.libraryId)
    },
}
</script>
